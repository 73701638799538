/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import ReactGA from 'react-ga4';
import { GetTokenSilentlyOptions, RedirectLoginOptions } from '@auth0/auth0-react';
import { createNotification } from './utilsFunctions';
import { AppThunk, RootState } from './store';
import { postData } from './api';
import { IGetStartedForm } from '../components/Modals/UserActionModal';
import { AccountInfoDtoIListResult, AhauOpenAPI, LicenseInfoDto } from '../gen/src/ahauOpenAPI';
import { getConfig } from '../config';
import { WebApi } from '../api/WebApi';
import { getCurrentPlanLevel } from '../helpers/pricing';
import { ErrorCodes } from './constants';
import { SessionStorageKeys, storage } from '../services/StorageService';

export type ErrorTypes =
  | 'AccessDenied'
  | 'ConsentRequired'
  | 'FetchFailed'
  | 'FormSubmissionFailed'
  | '';

interface AppState {
  isLoading: boolean;

  licenseData?: {
    data: LicenseInfoDto;
    expiredDate: string;
  };
  connectionsData?: AccountInfoDtoIListResult;

  isModal: boolean;
  modal: {
    type: string;
    action: string;
  };
  email: string;
  userEmail: string;
  successMessage: {
    showSuccessMessage: boolean;
    successMessageText: string;
  };
  error: {
    type: ErrorTypes;
    text: string;
  };
  accessToken: string;
  initialized: boolean;
}

const { audience } = getConfig();

const initialState: AppState = {
  isLoading: false,
  isModal: false,
  modal: {
    type: '',
    action: ''
  },
  email: '',
  userEmail: '',
  successMessage: {
    showSuccessMessage: false,
    successMessageText: ''
  },
  error: {
    type: '',
    text: ''
  },
  accessToken: '',
  initialized: false
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
    setLicenseData: (state, action: PayloadAction<LicenseInfoDto | null>) => {
      if (action.payload) {
        state.licenseData = {
          data: action.payload,
          expiredDate: action.payload.expiredDate.toString()
        };
      } else {
        state.licenseData = undefined;
      }

      // if (action.payload) {
      //   const { isTrial } = action.payload;
      //   const recurringMonths = get(action.payload, 'recurringMonths', 0);

      //   localStorage.setItem(
      //     'planLevel',
      //     recurringMonths
      //       ? `${getCurrentPlanLevel(recurringMonths, isTrial)}`
      //       : '0'
      //   );

      //   state.licenseData = {
      //     data: action.payload,
      //     expiredDate: action.payload.expiredDate.toString(),
      //   };
      // }
    },
    initialized: (state) => {
      state.initialized = true;
    },
    setConnectionsData: (
      state,
      action: PayloadAction<AccountInfoDtoIListResult>
    ) => {
      state.connectionsData = action.payload;
    },
    clearProfileData: (state) => {
      state.licenseData = undefined;
      state.connectionsData = undefined;
      state.accessToken = '';

      localStorage.removeItem('accessToken');
      localStorage.removeItem('planLevel');
    },
    setModal: (
      state,
      action: PayloadAction<{
        type?: string;
        action?: string;
      }>
    ) => {
      if (action.payload.type && action.payload.type !== 'GET_TOUR') {
        state.isModal = true;
      }

      state.modal = {
        type: action.payload.type ?? '',
        action: action.payload.action ?? ''
      };
    },
    closeModal: (state) => {
      state.isModal = false;
      state.modal = {
        action: '',
        type: ''
      };
    },
    setSuccessMessage: (
      state,
      action: PayloadAction<{
        show: boolean;
        text: string;
      }>
    ) => {
      state.successMessage = {
        showSuccessMessage: action.payload.show,
        successMessageText: action.payload.text
      };
    },
    setError: (
      state,
      action: PayloadAction<{
        type: ErrorTypes;
        text: string;
      }>
    ) => {
      state.error = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      state.initialized = true;
    },
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    }
  }
});

export const {
  startLoading,
  stopLoading,
  setLicenseData,
  setConnectionsData,
  clearProfileData,
  setModal,
  closeModal,
  setError,
  setSuccessMessage,
  setAccessToken,
  setUserEmail
} = appSlice.actions;

export const getProfileData = (
  getAccessTokenSilently: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>,
  loginWithRedirect: (options?: RedirectLoginOptions | undefined) => Promise<void>
): AppThunk => async (dispatch, getState) => {
  const { pathname: currentPathname, search: locationSearch } = getState().router.location;
  let api: AhauOpenAPI | null = null;
  
  try {
    const accessToken = await getAccessTokenSilently({
      audience
    });
    api = WebApi(accessToken);
    await api.sessionLoad();

    dispatch(setAccessToken(accessToken));
  } catch (e: any) {
    if (e.isApiException && e.response) {
      const response = JSON.parse(e.response);
      if (response && response.code === ErrorCodes.TenantCreationError) {
        const reloaded = storage().getSession<string>(SessionStorageKeys.ReloadIfErrorOcurred);

        if (!reloaded || ((Date.now() - Number(reloaded)) > 30 * 1000)) {
          storage().setSession(SessionStorageKeys.ReloadIfErrorOcurred, Date.now());
          window.location.reload();
        }
      }
      return;
    }
    const error: {
      type: ErrorTypes;
      text: string;
    } = {
      type: '',
      text: ''
    };

    switch (e.error) {
      case 'access_denied':
        error.type = 'AccessDenied';
        error.text = 'Something went wrong with the authorization process. Your access to your personal profile page is denied';
        break;
      case 'consent_required':
        error.type = 'ConsentRequired';
        error.text = 'Your consent for this account is required, so this account cannot be used to sign in as of now';
        break;
      case 'login_required':
        await loginWithRedirect({ appState: { returnTo: currentPathname + locationSearch } });
        return;
      default:
        error.type = '';
        error.text = 'Something went wrong';
        break;
    }

    dispatch(push('/error'));
    dispatch(setError(error));
    // return;
  }
  try {
    const licenseInfo = await api?.licensingGetLicenseInfo();
    
    licenseInfo && dispatch(setLicenseData(licenseInfo));
  } catch (e) {
    dispatch(setLicenseData(null));
  }


};

export const onSendUserActionForm = (
  userAction: string,
  form: IGetStartedForm,
  isMobile?: boolean,
  currentScrollPosition?: number
): AppThunk => async (dispatch, getState) => {
  const {
    modal: { type, action: analyticsAction }
  } = getState().appReducer;

  const actionsEndpoints: {
    contactUs: string;
  } = {
    contactUs: 'ContactUs'
  };

  userAction =
    userAction.charAt(0).toLowerCase() +
    userAction.slice(1).replace(/\s+/g, '');

  const currentUserAction =
    actionsEndpoints[userAction as keyof { contactUs: string; }];

  const {
    pathname: currentPathname,
    search: locationSearch
  } = getState().router.location;
  const returnUri = getReturnUri(locationSearch);
  const returnTo = isMobile ? returnUri : currentPathname;

  sendGoogleAnalyticsEvent(
    analyticsAction,
    `User clicked on ${analyticsAction} button`
  );

  try {
    dispatch(startLoading());
    await postData(
      `https://lic.ahau260.com/api/Contacts/${currentUserAction}`,
      form
    );
    dispatch(
      setSuccessMessage({
        show: true,
        text: 'Thank you! We will contact you as soon as possible.'
      })
    );
  } catch (e) {
    createNotification(
      'Something went wrong with your form submission!',
      (e as Error).message
    );
    dispatch(closeModal());
    dispatch(stopLoading());

    dispatch(
      push({
        pathname: '/error',
        search: `?returnUri=${encodeURIComponent(returnTo!)}`,
        state: {
          modalType: type,
          scrollPosition: currentScrollPosition
        }
      })
    );

    return dispatch(
      setError({
        type: 'FormSubmissionFailed',
        text: `Your submission attempt of ${analyticsAction} form failed`
      })
    );
  }

  dispatch(stopLoading());
  return dispatch(push(returnUri!));
};

export const getReturnUri = (path: string): string | undefined => {
  const query = new URLSearchParams(path);
  return (
    (query.has('returnUri') ? query.get('returnUri') : undefined) || undefined
  );
};

export const sendGoogleAnalyticsEvent = (category: string, action: string) => {
  ReactGA.event({
    category,
    action
  });
};

// Routing
export const redirectMobileAction = (
  destineLocation: string,
  skipSearchParams?: boolean,
  scrollPosition?: number
): AppThunk => (dispatch, getState) => {
  const {
    router: { location: currentLocation }
  } = getState();
  const returnUri = !skipSearchParams
    ? `${currentLocation.pathname}${currentLocation.search}`
    : `${currentLocation.pathname}`;

  dispatch(
    push({
      pathname: destineLocation,
      search: `?returnUri=${encodeURIComponent(returnUri)}`,
      state: {
        scrollPosition
      }
    })
  );
};

// Selectors

export const selectSession = (state: RootState) => state.appReducer;
export const selectedIsLoading = (state: RootState) =>
  state.appReducer.isLoading;
export const selectLicenseData = (state: RootState) => {
  const recurringMonths = get(
    state.appReducer,
    'licenseData.data.recurringMonths',
    0
  );
  const isTrial = get(state.appReducer, 'licenseData.data.isTrial', true);

  return {
    ...state.appReducer.licenseData,
    expiredDate: new Date(state.appReducer.licenseData?.expiredDate!),
    planLevel: recurringMonths
      ? getCurrentPlanLevel(recurringMonths, isTrial)
      : 0
  };
};
export const selectConnectionsData = (state: RootState) =>
  state.appReducer.connectionsData;
export const selectLoginModal = (state: RootState) => state.appReducer.isModal;
export const selectTypeOfModal = (state: RootState) =>
  state.appReducer.modal.type;
export const selectEmail = (state: RootState) => state.appReducer.email;
export const selectSuccessMessage = (state: RootState) =>
  state.appReducer.successMessage;
export const selectError = (state: RootState) => state.appReducer.error;
export const selectRouterAction = (state: RootState) => state.router.action;
export const selectAccessToken = (state: RootState) =>
  state.appReducer.accessToken;

export const selectAppState = (state: RootState) =>
  state.appReducer;

export default appSlice.reducer;
